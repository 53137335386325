import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import FacebookIcon from '@static/icons/facebook.png';
import InstagramIcon from '@static/icons/instagram.png';
import PriceImg from '@static/new-web/price.png';
import CoverImg from '@static/new-web/cover_mobile.jpg';
import DrobeboxLogo from '@static/app_icon.png';

import ExternalLink from '@common/ExternalLink';


const SOCIAL = [
  {
    icon: FacebookIcon,
    link: 'https://www.facebook.com/DrobeBox/',
  },
  {
    icon: InstagramIcon,
    link: 'https://www.instagram.com/drobebox.official/',
  }
];

const Header = () => {
  const [targetURL, setTargetURL] = useState({});
  useEffect(() => {
    setTargetURL({path: window.location.pathname, query: window.location.search});
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <HeaderWrapper id="header">
        <NavContainer>
          <Brand>
            <Link to='/'>
              <Logo src={DrobeboxLogo} alt="Logo" />
            </Link>
          </Brand>
          <SocialIcons>
            {SOCIAL.map(({ icon, link }) => (
              <ExternalLink href={link}>
                <img src={icon} alt="link" />
              </ExternalLink>
            ))}
          </SocialIcons>
        </NavContainer>
        <Row>
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 3, position: 'relative', marginLeft: '22px' }}>
            <Cover src={CoverImg} />
            <TitleContainer>
              <Row>
                <Title>
                  Tủ đồ hàng hiệu
              </Title>
              </Row>
              <Row style={{ alignItems: 'center' }}>
                <Line />
                <Title style={{ flex: 2 }}>
                  Sành điệu<br />
                  Ra đường
              </Title>
              </Row>
            </TitleContainer>
          </div>
        </Row>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2rem' }}>
          <Only>
            chỉ với
          </Only>
          <Price src={PriceImg} />
          <SubTitle>
            Tự mua sắm, tự giặt giũ và luôn sẵn sàng cùng bạn cho mọi sự thay đổi.
          </SubTitle>
        </div>
        {
          targetURL.path ?
          <GoToAppContainer>
            <AppLink href={`https://app.drobebox.com${targetURL.path}${targetURL.query}`}>MỞ ỨNG DỤNG</AppLink>
          </GoToAppContainer> : React.Fragment
        }
      </HeaderWrapper>
    </div>
  );
};

const Row = styled.div`
  display: flex;
`

const HeaderWrapper = styled.header`
  position: relative;
  background-color: ${props => props.theme.color.white.regular};
  height: calc(calc(100vw - 22px) * 1.74);
`;

const Brand = styled.div`
  z-index: 200;
  ${props => props.theme.font_size.large};
  img {
    width: 48px;
  };
  flex: 1;
`;

const Logo = styled.img`
  max-height: 69px;
`

const NavContainer = styled(Row)`
  display: flex;
  width: 100%;
  padding: 2rem;
  padding-top: 1rem;
  align-items: center;
  z-index: 200;
  position: absolute;
  top:0;
  left:0;
`

const SocialIcons = styled.div`
  flex: 3;
  display: flex;
  padding: 1rem;
  padding-right: 0;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 20px;
    height: 20px;
    margin: 0 1rem;
  }
`;

const Title = styled.h1`
  width: 100%;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #0e0f1e;
  text-transform: uppercase;
  z-index: 200;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  z-index: 200;
`

const SubTitle = styled.h4`
  width: 125px;
  padding-top: 1rem;  
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #1b1b2a;
  z-index: 200;
`

const Line = styled.div`
  flex: 1;
  height: 1px;
  border: solid 1px #000000;
  z-index: 200;
  margin-bottom: 18px;
`

const Only = styled.p`
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.45;
  letter-spacing: normal;
  color: #0e0f1e;
  z-index: 200;
`

const Price = styled.img`
  width: 125px;
  z-index: 200;
`

const Cover = styled.img`
  width: 100%;
  position: absolute;
  right: 0;
  z-index: 150;
  overflow: hidden;
`

const GoToAppContainer = styled.div`
  display: flex;
  width: 100vw;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 300;
  padding: 1rem;
  background: white;
  a:hover {
    color: white;
  }
  box-shadow: inset 0 1px #fff, 0 1px 3px rgba(34,25,25,0.4);
`

const AppLink = styled(OutboundLink)`
  z-index: 200;
  width: 300px;
  height: 60px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 8px;
`
export default Header;
